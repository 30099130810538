(function ($) {
    //*****************************************
    // Global Site Specific JS
    //*****************************************
    //
    //	Functions
    //	-> List the site functions and brief desc in this file
    //	-> thisIsAnExampleFunction - does some stuff
    //	-> aDifferentExampleFunction - does other stuff
    //
    //*****************************************


    //--------------------------------------------------------------------
    //   Doc Ready - There shouldnt be more than one of these in this file
    //--------------------------------------------------------------------

    $(document).ready(function (e) {

        //------------------------------------------
        //  reCaptcha Function
        //------------------------------------------

        /*window.imNotARobot = function() {
            document.querySelectorAll('.g-recaptcha-error').forEach(function(item){
                item.remove();;
            })
        }*/

        //------------------------------------------
        //  Phone Context Modal
        //------------------------------------------

        if ($('.js-phoneContextModal').length) {
            $('.js-phoneContextModal').magnificPopup({ type: 'inline' });
        }

        //------------------------------------------
        //  Review form Modal
        //------------------------------------------

        if ($('.js-reviewFormContextModal').length) {
            $('.js-reviewFormContextModal').magnificPopup({ type: 'inline' });
        }

        //------------------------------------------
        //  Press form Modal
        //------------------------------------------

        if ($('.js-formContextModal').length) {
            $('.js-formContextModal').magnificPopup({ type: 'inline' });
        }

        //--------------------------------------------------------------------
        //   Nav Site Search Toggle
        //--------------------------------------------------------------------
        $('.js-toggleSiteSearch').on('click', function (e) {
            e.preventDefault();
            toggleSiteSearch();
        })

        function toggleSiteSearch() {
            var $wrapper = $('.c-site-search'),
                $toggle = $('.c-site-search__toggle');

            $wrapper.toggleClass('is-active');
            $toggle.toggleClass('is-active');

            if ($wrapper.hasClass('is-active')) {
                $('.c-site-search__input').focus();
            }
        }

        //--------------------------------------------------------------------
        //   Podcast Transcript
        //--------------------------------------------------------------------
        if ($('.transcript__btn').length) {
            $(document).on('click', '.transcript__btn', function (e) {
                e.preventDefault();
                $('.transcript__wrapper').slideToggle(600, 'swing');
                $(this).text($(this).text() == 'Transcript -' ? 'Transcript +' : 'Transcript -');
            })
        }


        //--------------------------------------------------------------------
        //   Directory Toggle
        //--------------------------------------------------------------------
        if ($('.js-directory-tab').length) {
            $(document).on('click', '.js-directory-tab', function (e) {
                e.preventDefault();
                var ref = $(this).data("toggle");
                if ($(this).hasClass("is-active") === false) {
                    $('.c-directory__tab').toggleClass('is-active');
                    $('.c-directory__panel').toggleClass('is-active');
                }
            })
        }

        if ($('.js-directory-toggle').length) {
            $(document).on('click', '.js-directory-toggle', function (e) {
                e.preventDefault();
                var ref = $(this).data("toggle");
                if ($(this).hasClass("is-active") === false) {
                    $('.c-directory-browse__btn').removeClass("is-active");
                    $('.c-directory-browse__list').removeClass("is-active");
                    $(this).addClass("is-active");
                    $(ref).addClass("is-active");
                }
            })
        }


        /*
        //------------------------------------------
        //  Fact Check Modal
        //------------------------------------------

        if( $('.js-factCheckModal').length ) {
            $('.js-factCheckModal').magnificPopup({ type: 'inline' });
        }


        //------------------------------------------
        //  Hero JumpTo Mobile Expand
        //------------------------------------------

        $('.c-hero__bottom-menu').on('click', '.c-jump-to__title', function() {
            var $jumpTo = $(this).parents('.c-jump-to'),
                $jumpToList = $jumpTo.find('.c-jump-to__list');
            $jumpTo.toggleClass('is-active');
            $jumpToList.slideToggle();
        });
        */

        //------------------------------------------
        //  Simple Offer
        //------------------------------------------
        var SimpleOffer = {
            simpleOffer: {},
            buttons: {},
            closeButtons: {},
            simpleOfferId: 'simple-offer',
            simpleOfferModifier: 'is-active',
            buttonsClass: 'c-button',
            closeButtonsClass: 'c-simple__close',
            delay: 200,
            cookie: {
                name: 'simple_offer',
                value: true,
                exdays: 0.4,
                path: '/'
            },

            // check if the simple offer cookie has been set
            checkCookie: function () {
                return getCookie('simple_offer');
            },

            // set the simple offer cookie
            setCookie: function () {
                var cookie = this.cookie;
                setCookie(cookie.name, cookie.value, cookie.exdays, cookie.path);
            },
            // show the simple offer
            show: function () {
                this.simpleOffer.classList.add(this.simpleOfferModifier);
            },
            // hide the simple offer
            hide: function () {
                this.simpleOffer.classList.remove(this.simpleOfferModifier);
            },
            // get the simple offer element
            getSimpleOffer: function () {
                this.simpleOffer = document.getElementById(this.simpleOfferId);
            },
            // sets all the simple offer elements
            getButtons: function () {
                this.buttons = this.simpleOffer.getElementsByClassName(this.buttonsClass);
                this.closeButtons = document.getElementsByClassName(this.closeButtonsClass);
            },
            // check if the page has a simple offer
            enabled: function () {
                var enabled = this.simpleOffer ? true : false;
                return enabled;
            },
            init: function () {
                // get the simple offer elements
                this.getSimpleOffer();

                // get the buttons
                this.getButtons();

                //show simple offer after short delay
                setTimeout(function () {
                    SimpleOffer.show();
                }, this.delay);

                // add onclick functions to simple offer close buttons
                for (var i = 0; i < this.closeButtons.length; i++) {
                    this.closeButtons[i].onclick = function () {
                        var offer = SimpleOffer.buttons[0].getAttribute('href');
                        window.simpleOfferClosed(offer);
                        SimpleOffer.setCookie();
                        SimpleOffer.hide();
                    }
                }
            } //end init
        } //end SimpleOffer

        //check for simple offer cookie and elemnt before init. Less JS to run
        if (!getCookie('simple_offer') && document.getElementById("simple-offer")) {

            var timer;
            var startingPosition = $(document).scrollTop();//have a starting position so it won't load automattically on refresh or back button use

            //add scroll event listener so we can cleanup when no longer needed
            window.addEventListener('scroll', scrollListener);

            //fuction to check scroll position every 100ms and init the simple offer
            function scrollListener() {
                if (timer) {
                    window.clearTimeout(timer);
                }

                //function that runs every 100ms
                timer = window.setTimeout(function () {
                    //if window top is greater than 10% of document height, show simple offer
                    if ($(document).scrollTop() > startingPosition && $(document).scrollTop() > $(document).height() * 0.1) {
                        SimpleOffer.init();
                        window.removeEventListener('scroll', scrollListener);
                    }
                }, 100);
            }
        }

        //------------------------------------------
        //  Sticky Sidebar
        //------------------------------------------

        if ($('.c-cta--sticky').length > 0) {

            // Sidebar CTA
            $(window).scroll(function (event) {
                var st = $(this).scrollTop();

                if (st > 1800) {
                    $('.c-cta--sticky').addClass('is-active');
                } else {
                    // Hide within 900px of top of window
                    $('.c-cta--sticky').removeClass('is-active');
                }

                // Hide before footer on pages without support
                if ($('html').hasClass('no-csspositionsticky')) {
                    if ($(window).scrollTop() + $(window).height() > $(document).height() - 1600) {
                        $('.c-cta--sticky').removeClass('is-active');
                    }
                }

            });

        }

        //------------------------------------------
        // Sticky Banner
        //------------------------------------------

        // Sticky banner
        if ($('.c-banner').length > 0) {

            $('.js-deactivate').on('click', function (e) {
                $('.c-banner').addClass('is-disabled');
            });

            $(function () {
                var lastScrollTop = 1, delta = 15;

                $(window).scroll(function (event) {
                    var st = $(this).scrollTop();

                    if (!$('.c-banner').hasClass('is-disabled')) {
                        if (st > 600) {
                            if (Math.abs(lastScrollTop - st) <= delta)
                                return;
                            if ((st > lastScrollTop) && (lastScrollTop > 0)) {
                                // Scrolling Down
                                $('.c-banner').addClass('is-active');
                            } else {
                                // Scrolling Up
                                $('.c-banner').removeClass('is-active');
                            }
                            lastScrollTop = st;
                        } else {
                            // Hide within 600px of page top
                            $('.c-banner').removeClass('is-active');
                        }
                    }

                });
            });
        }

        //------------------------------------------
        //  Mobile Hub
        //------------------------------------------

        if ($('.c-mobile-hub__btn').length > 0) {

            // Mobile Hub appears
            $(window).scroll(function (event) {
                var st = $(this).scrollTop();

                if (st > 1400) {
                    $('.c-mobile-hub__btn').addClass('is-visible');
                } else {
                    // Hide within 600px of top of window
                    $('.c-mobile-hub__btn').removeClass('is-visible');
                }

                if ($(window).scrollTop() + $(window).height() > $(document).height() - 1400) {
                    // hide before footer
                    $('.c-mobile-hub__btn').removeClass('is-visible');
                }

            });

            // float button
            $('.c-mobile-hub__btn').on('click', function (e) {
                e.preventDefault();
                toggleMobileHub();
            });

            function toggleMobileHub() {
                $('body').toggleClass('c-mobile-hub--open');
                $('.c-mobile-hub__mask').toggleClass('is-active');
                $('.site-header').removeClass('is-fixed');

                // button
                $('.c-mobile-hub__ellipsis').toggleClass('is-open');

                // only sidebar
                $('.c-mobile-hub__sidebar').toggleClass('is-open');

                // close CTA if exists
                if ($('.c-banner')) {
                    $('.c-banner').removeClass('is-active');
                }
            }

            $('.c-mobile-hub__close').on('click', function (e) {
                e.preventDefault();
                toggleMobileHub();
            });

            // clicking a jump-to link
            $('.c-mobile-hub .c-jump-to__item a').on('click', function (e) {
                e.preventDefault();
                toggleMobileHub();
                window.location = this.href;//start jump to animation after hub closes
            });

            // clicking outside the element
            $(document).bind('mouseup touchend', function (e) {
                if ($('body').hasClass('c-mobile-hub--open')) {
                    // items within MH or Phone Context modal
                    var menuClick = $('.c-mobile-hub__sidebar, .c-mobile-hub__btn, .js-phoneContextModal, .mfp-content');

                    if (!menuClick.is(e.target) && menuClick.has(e.target).length === 0) {
                        $('.c-mobile-hub__btn').trigger('click');
                    }
                }
            });

        }

        //------------------------------------------
        //  FAQ List Component
        //------------------------------------------

        $('.c-faq__question').click(function (e) {
            e.preventDefault();
            $(this).toggleClass("c-faq__question--active").next().slideToggle();
        });

        //------------------------------------------
        //  Third-Party Advertiser Param
        //------------------------------------------

        if ($('.c-advertisement').length > 0) {
            // check for mixpanel id 
            if (getCookie('mixp_id')) {
                var mp_id = getCookie('mixp_id');
                // Add the cookie value to the url param 
                $('.c-advertisement').each(function (index) {
                    var advertisement_href = $(this).find("a"); // find the a tag
                    var _href = advertisement_href.attr("href");  // get the href value 
                    // figure out if we need to kick of with a "?" or add to it with "&"
                    var param = (_href.indexOf("?") >= 0) ? '&campaign=' + mp_id : '?campaign=' + mp_id;
                    // set the url params to href
                    advertisement_href.attr("href", _href + param);
                });
            }
        }

        //------------------------------------------
        //    Clickable
        //    Note: Makes full Element clickable as a link (must have an a tag within)
        //------------------------------------------
        $(".clickable").click(function () {
            $(this).find("a")[0].click();
        });

        //------------------------------------------
        //    Show More Timeline
        //    Note: Makes full Element clickable as a link (must have an a tag within)
        //------------------------------------------

        if ($('.js-showTimeline').length > 0) {
            $('.js-showTimeline').click(function (e) {
                e.preventDefault();
                $(this).prev('.c-timeline--hide').removeClass('c-timeline--hide');
                $(this).hide();
            });
        }

    }); // End of Doc Ready


})(jQuery);
